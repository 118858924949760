<template>
    <nav class="breadcrumb">
        <div v-for="(crumb, index) in breadcrumbs"
             :key="index"
             class="text-16 whitespace-nowrap flex items-center">
            <router-link
                v-if="crumb.pathName"
                :to="{ name: crumb.pathName }">
                {{ crumb.text }}
            </router-link>
            <p v-else>
                {{ crumb.text }}
            </p>
            <CIcon v-if="index !== breadcrumbs.length - 1" name="chevron-right"/>
        </div>
    </nav>
</template>

<script lang="ts">
import dictionary from '@/core/dictionary/dictionary';
import router from '@/router/router.index';
import { RouteMetaExtended } from '@/router/router.routes';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'Breadcrumb',
    props: {
        append: {
            type: Array as PropType<{ text: string, pathName?: string }[]>,
            required: false,
        },
    },
    setup(props) {
        const breadcrumbs = computed(() => {
            const crumbs: { text: string, pathName?: string }[] = [];
            const matchedRoutes  = router.currentRoute.value.matched;

            for (const routeRecord of matchedRoutes) {
                const meta = routeRecord.meta as RouteMetaExtended;
                
                if (meta.breadcrumb) {
                    for (const crumb of meta.breadcrumb) {
                        const title = dictionary.has(crumb.text) ? dictionary.get(crumb.text) : crumb.text;
                        crumbs.push({ text: title, pathName: crumb.pathName });
                    }
                }
            }

            if (props.append) {
                crumbs.push(...props.append);
            }

            return crumbs;
        });

        return {
            breadcrumbs,
        };
    },
});
</script>

<style lang="scss" scoped>
.breadcrumb {
    display: flex;
    align-items: center;
    width: 100%;

    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }
}
</style>
