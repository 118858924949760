<template>
    <div ref="menu"
         @mouseenter="e => handleToggle(true, e)"
         @mouseleave="e => handleToggle(false, e)">
        <router-link :to="{ path: node.category.url }">
            <p class="px-4 text-14 py-1 flex items-center justify-between"
               :class="{ 'bg-gray-300': active }">
                <span>{{ node.category.name }}</span>
                <CIcon v-if="node.children.length > 0 || node.products.length > 0" name="chevron-right"/>
            </p>
        </router-link>

        <div v-if="active && (node.children.length || node.products.length)" ref="subMenuOverlay" class="submenu-overlay">
            <template v-if="node.children.length > 0">
                <HeaderDropdownCategoryNode v-for="child in node.children"
                                            :key="child.category.id"
                                            :node="child"
                                            :parent="node"
                                            :index="subMenusIndex + 1"/>
            </template>

            <template v-if="node.products.length > 0">
                <router-link v-for="product in node.products"
                             :key="product.id"
                             :to="{ path: getProductUrl(product) }"
                             class="">
                    <p class="px-4 text-14 py-1 hover:bg-gray-300 flex items-center justify-between">
                        <span>{{ product.name }}</span>
                    </p>
                </router-link>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent, onMounted, ref } from 'vue';
import { getProductUrl } from '@/core/compositions/products.compositions';
import { CategoryTreeNode } from '@/api';

const MENU_WIDTH = 285;

export default defineComponent({
    name: 'HeaderDropdownCategoryNode',
    props: {
        node: {
            type: Object as PropType<CategoryTreeNode>,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const active = ref(false);
        const menu = ref<HTMLDivElement | null>(null);
        const subMenuOverlay = ref<HTMLDivElement | null>(null);
        const subMenuTopPosition = ref('0px');
        const subMenuLeftPosition = ref('100%');
        const subMenusOutOfBounds = ref(false);
        const subMenusIndex = ref(props.index);

        onMounted(() => {
            if (!menu.value)
                return;

            const menuRect = menu.value.getBoundingClientRect();
            subMenusOutOfBounds.value = menuRect.right + MENU_WIDTH > window.innerWidth;
            if (subMenusOutOfBounds.value)
                subMenusIndex.value -= 2;
           
            subMenuLeftPosition.value = `calc(100% + ${MENU_WIDTH * subMenusIndex.value - subMenusIndex.value * 2}px)`;

            subMenuTopPosition.value = `${menuRect.top - 75}px`;
        });

        const handleToggle = (state: boolean, e: MouseEvent) => {
            active.value = state;

            if (!state) 
                return;

            setTimeout(() => {
                if (subMenuOverlay.value) {
                    const rect = subMenuOverlay.value.getBoundingClientRect();
                    const isOutOfBounds = rect.bottom > window.innerHeight;
                    if (isOutOfBounds) {
                        if (rect.bottom > window.innerHeight) {
                            const excessHeight = rect.bottom - window.innerHeight;
                            subMenuTopPosition.value = `${rect.top - excessHeight - 89}px`;
                        } else if (rect.top < 0) {
                            subMenuTopPosition.value = '15px';
                        }
                    }
                }
            });
        };

        return {
            menu,
            active,
            subMenuOverlay,
            subMenuTopPosition,
            subMenuLeftPosition,
            subMenusOutOfBounds,
            subMenusIndex,
            getProductUrl,
            handleToggle,
        };
    },
});
</script>

<style lang="scss" scoped>
.submenu-overlay {
    position: fixed;
    top: v-bind(subMenuTopPosition);
    left: v-bind(subMenuLeftPosition);

    background-color: theme('colors.elements');

    width: 285px;
    max-width: 90vw;
    max-height: 70vh;
    overflow: auto;
    z-index: 1;

    border: 3px solid theme('colors.green.primary');

    @apply shadow-lg;
}
</style>
