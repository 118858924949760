import { ProductCategoryViewObject, ProductCustomerAssetViewObject, ProductGroupLockViewObject, ProductGroupViewObject, ProductLockViewObject, ProductViewObject } from '@/api';
import { MaybeRef, Ref, computed, ref, unref, watch } from 'vue';
import { mergeObject } from '@/core/util/mergeObjects';
import { userStore } from '@/core/store/user/user.store';
import { watchDebounced } from '@vueuse/core';
import productsApiService from '@/core/api/controllers/productsApi.service';
import productBulkFetch from '@/core/bulk/services/productBulkFetch';
import productsBulkFetch from '@/core/bulk/services/productsBulkFetch';
import { useBusEvent, useBusEvents } from '@/core/bus';
import { isEqual } from 'lodash-es';

const { activeCustomer } = userStore;

export function hasAccessToProduct(product: ProductViewObject) {
    return !product.hidden &&
           activeCustomer.value &&  
          (activeCustomer.value.showProductsWithoutAccess || (product.customerAccesses.includes(activeCustomer.value.id) || activeCustomer.value.productGroupAccesses.some(groupId => product.productGroupAccesses.includes(groupId))));
}

export function canPurchaseProduct(product: ProductViewObject) {
    return !product.hidden &&
           activeCustomer.value &&  
          (product.customerAccesses.includes(activeCustomer.value.id) || activeCustomer.value.productGroupAccesses.some(groupId => product.productGroupAccesses.includes(groupId)));
}

export function useProduct(productId: Ref<string>, onUpdate?: () => void) {
    const product = ref<ProductViewObject | null>(null);

    watch(productId, async() => {
        const result = await productBulkFetch.requestData(productId.value);
        addOrUpdate(result);
    }, { immediate: true });

    const addOrUpdate = (data?: ProductViewObject) => {
        if (data && data?.id === productId.value) {
            product.value = data;
            onUpdate?.();
        }
    };

    const addOrUpdateMultiple = (data: ProductViewObject[]) => {
        data.forEach(x => addOrUpdate(x));
    };

    const remove = (removedId: string) => {
        if (removedId === productId.value) {
            product.value = null;
        }
    };

    const lock = (lock: ProductLockViewObject) => {
        if (product.value && lock.entityId === productId.value) {
            product.value.lock = lock;
        }
    };

    const unlock = (lock: ProductLockViewObject) => {
        if (product.value && lock.entityId === productId.value) {
            product.value.lock = null;
        }
    };

    useBusEvents({
        'ProductUpdated': addOrUpdate,
        'ProductsUpdated': addOrUpdateMultiple,
        'ProductCreated': addOrUpdate,
        'ProductRemoved': remove,
        'LockProduct': lock,
        'UnlockProduct': unlock,
    });

    return {
        product,
    };
}

export function useProducts(productIds?: Ref<string[]> | null, onInitialized?: () => void) {
    const products = ref<ProductViewObject[]>([]);

    const addOrUpdate = (data: ProductViewObject) => {
        const existing = products.value.find(x => x.id === data.id);
        if (existing) {
            mergeObject(existing, data);
        } else {
            products.value.push(data);
        }
    };

    const addOrUpdateMultiple = (data: ProductViewObject[]) => {
        data.forEach(x => addOrUpdate(x));
    };

    const remove = (productId: string) => {
        const index = products.value.findIndex(x => x.id === productId);
        if (index >= 0) {
            products.value.splice(index, 1);
        }
    };

    const lock = (lock: ProductLockViewObject) => {
        const task = products.value.find(x => x.id === lock.entityId);
        if (task) {
            task.lock = lock;
        }
    };

    const unlock = (lock: ProductLockViewObject) => {
        const task = products.value.find(x => x.id === lock.entityId);
        if (task) {
            task.lock = null;
        }
    };

    if (productIds) {
        watch(productIds, async(_, previousProductIds) => {
            if (productIds.value.length === 0) {
                products.value = [];
                return;
            }

            if (isEqual(productIds.value, previousProductIds))
                return;
        
            const result = await productsBulkFetch.requestData(productIds.value);
            addOrUpdateMultiple(result ?? []);
            onInitialized?.();
        }, { immediate: true });
    } else {
        productsApiService.getProducts().then(result => {
            products.value = result?.products ?? [];
            onInitialized?.();
        });
    }

    useBusEvents({
        'ProductUpdated': addOrUpdate,
        'ProductsUpdated': addOrUpdateMultiple,
        'ProductCreated': addOrUpdate,
        'ProductRemoved': remove,
        'LockProduct': lock,
        'UnlockProduct': unlock,
    });

    return {
        products,
    };
}

export function useAvailableProducts(onInitialized?: () => void) {
    const products = ref<ProductViewObject[]>([]);

    const addOrUpdate = (data: ProductViewObject) => {
        const productIsAvailable = hasAccessToProduct(data);

        const existing = products.value.find(x => x.id === data.id);
        if (existing) {
            if (productIsAvailable)
                mergeObject(existing, data);
            else
                remove(data.id);
        } else if (productIsAvailable) {
            products.value.push(data);
        }
    };

    const addOrUpdateMultiple = (data: ProductViewObject[]) => {
        data.forEach(x => addOrUpdate(x));
    };

    const remove = (productId: string) => {
        const index = products.value.findIndex(x => x.id === productId);
        if (index >= 0) {
            products.value.splice(index, 1);
        }
    };

    const lock = (lock: ProductLockViewObject) => {
        const task = products.value.find(x => x.id === lock.entityId);
        if (task) {
            task.lock = lock;
        }
    };

    const unlock = (lock: ProductLockViewObject) => {
        const task = products.value.find(x => x.id === lock.entityId);
        if (task) {
            task.lock = null;
        }
    };

    (async function() {
        const result = await productsApiService.getAvailableProducts();
        products.value = result?.products ?? [];
        onInitialized?.();
    })();

    useBusEvents({
        'ProductUpdated': addOrUpdate,
        'ProductsUpdated': addOrUpdateMultiple,
        'ProductCreated': addOrUpdate,
        'ProductRemoved': remove,
        'LockProduct': lock,
        'UnlockProduct': unlock,
    });

    return {
        products,
    };
}

export function useProductsForCustomer(onInitialized?: () => void) {
    const products = ref<ProductViewObject[]>([]);

    const addOrUpdate = (data: ProductViewObject) => {
        const productIsAvailableForCustomer = hasAccessToProduct(data);

        const existing = products.value.find(x => x.id === data.id);
        if (existing) {
            if (productIsAvailableForCustomer)
                mergeObject(existing, data);
            else
                remove(data.id);
        } else if (productIsAvailableForCustomer) {
            products.value.push(data);
        }
    };

    const addOrUpdateMultiple = (data: ProductViewObject[]) => {
        data.forEach(x => addOrUpdate(x));
    };

    const remove = (productId: string) => {
        const index = products.value.findIndex(x => x.id === productId);
        if (index >= 0) {
            products.value.splice(index, 1);
        }
    };

    const lock = (lock: ProductLockViewObject) => {
        const task = products.value.find(x => x.id === lock.entityId);
        if (task) {
            task.lock = lock;
        }
    };

    const unlock = (lock: ProductLockViewObject) => {
        const task = products.value.find(x => x.id === lock.entityId);
        if (task) {
            task.lock = null;
        }
    };

    watch(activeCustomer, async() => {
        if (!activeCustomer.value) {
            products.value = [];
            return;
        }
        const result = await productsApiService.getAvailableProductsForCustomer();
        products.value = result?.products ?? [];
        onInitialized?.();
    }, { immediate: true });

    useBusEvents({
        'ProductUpdated': addOrUpdate,
        'ProductsUpdated': addOrUpdateMultiple,
        'ProductCreated': addOrUpdate,
        'ProductRemoved': remove,
        'LockProduct': lock,
        'UnlockProduct': unlock,
    });

    return {
        products,
    };
}

export function useProductGroup(groupId: Ref<string>, onUpdate?: () => void) {
    const group = ref<ProductGroupViewObject | null>(null);

    watch(groupId, async(a, b) => {
        const result = await productsApiService.getProductGroup(groupId.value);
        addOrUpdate(result);
    }, { immediate: true });

    const addOrUpdate = (data?: ProductGroupViewObject) => {
        if (data?.id === groupId.value) {
            group.value = data;
            onUpdate?.();
        }
    };

    const addOrUpdateMultiple = (data: ProductGroupViewObject[]) => {
        data.forEach(x => addOrUpdate(x));
    };

    const remove = (removedId: string) => {
        if (removedId === groupId.value) {
            group.value = null;
        }
    };

    const lock = (lock: ProductGroupLockViewObject) => {
        if (group.value && lock.entityId === groupId.value) {
            group.value.lock = lock;
        }
    };

    const unlock = (lock: ProductGroupLockViewObject) => {
        if (group.value && lock.entityId === groupId.value) {
            group.value.lock = null;
        }
    };

    useBusEvents({
        'ProductGroupUpdated': addOrUpdate,
        'ProductGroupsUpdated': addOrUpdateMultiple,
        'ProductGroupCreated': addOrUpdate,
        'ProductGroupRemoved': remove,
        'LockProductGroup': lock,
        'UnlockProductGroup': unlock,
    });

    return {
        group,
    };
}

export function useProductGroups() {
    const groups = ref<ProductGroupViewObject[]>([]);

    (async function() {
        const result = await productsApiService.getProductGroups();
        if (result) {
            groups.value = result.productGroups;
        }
    })();

    const addOrUpdate = (data: ProductGroupViewObject) => {
        const existing = groups.value.find(x => x.id === data.id);
        if (existing) {
            mergeObject(existing, data);
        } else {
            groups.value.push(data);
        }
    };

    const addOrUpdateMultiple = (data: ProductGroupViewObject[]) => {
        data.forEach(x => addOrUpdate(x));
    };

    const remove = (group: string) => {
        const index = groups.value.findIndex(x => x.id === group);
        if (index >= 0) {
            groups.value.splice(index, 1);
        }
    };

    const lock = (lock: ProductGroupLockViewObject) => {
        const task = groups.value.find(x => x.id === lock.entityId);
        if (task) {
            task.lock = lock;
        }
    };

    const unlock = (lock: ProductGroupLockViewObject) => {
        const task = groups.value.find(x => x.id === lock.entityId);
        if (task) {
            task.lock = null;
        }
    };

    useBusEvents({
        'ProductGroupUpdated': addOrUpdate,
        'ProductGroupsUpdated': addOrUpdateMultiple,
        'ProductGroupCreated': addOrUpdate,
        'ProductGroupRemoved': remove,
        'LockProductGroup': lock,
        'UnlockProductGroup': unlock,
    });

    return {
        groups,
    };
}

export function useProductCategories() {
    const categories = ref<ProductCategoryViewObject[]>([]);

    (async function() {
        const result = await productsApiService.getProductCategories();
        if (result) {
            categories.value = result.categories;
        }
    })();

    const addOrUpdate = (data: ProductCategoryViewObject) => {
        const existing = categories.value.find(x => x.id === data.id);
        if (existing) {
            if (existing.parent !== data.parent) {
                if (data.parent) {
                    const parent = categories.value.find(x => x.id === data.parent);
                    if (parent) {
                        const index = parent.children.indexOf(data.id);
                        if (index < 0) {
                            parent.children.push(data.id);
                        }
                    }
                }

                if (existing.parent) {
                    const parent = categories.value.find(x => x.id === existing.parent);
                    if (parent) {
                        const index = parent.children.indexOf(data.id);
                        if (index >= 0) {
                            parent.children.splice(index, 1);
                        }
                    }
                }
            }
            mergeObject(existing, data);
        } else {
            categories.value.push(data);

            if (data.parent) {
                const parent = categories.value.find(x => x.id === data.parent);
                if (parent) {
                    const index = parent.children.indexOf(data.id);
                    if (index < 0) {
                        parent.children.push(data.id);
                    }
                }
            }
        }
    };

    const addOrUpdateMultiple = (data: ProductCategoryViewObject[]) => {
        data.forEach(x => addOrUpdate(x));
    };

    const remove = (categoryId: string) => {
        const index = categories.value.findIndex(x => x.id === categoryId);
        if (index >= 0) {
            categories.value.splice(index, 1);
        }

        categories.value.forEach(category => {
            const index = category.children.indexOf(categoryId);
            if (index >= 0) {
                category.children.splice(index, 1);
            }
        });
    };

    const removeMultiple = (categoryIds: string[]) => {
        categoryIds.forEach(categoryId => remove(categoryId));
    };

    useBusEvents({
        'ProductCategoryUpdated': addOrUpdate,
        'ProductCategoriesUpdated': addOrUpdateMultiple,
        'ProductCategoryCreated': addOrUpdate,
        'ProductCategoryRemoved': remove,
        'ProductCategoriesRemoved': removeMultiple,
    });

    return {
        categories,
    };
}

export interface CategoryNode {
    category: ProductCategoryViewObject;
    parent?: CategoryNode;
    children: CategoryNode[];
    products: ProductViewObject[];
}

export function useCategoryTree(categories: Ref<ProductCategoryViewObject[]>, availableProducts?: Ref<ProductViewObject[]>, sort = false) {
    
    function pruneTree(node: CategoryNode): boolean {
        node.children = node.children.filter(child => pruneTree(child));
        return node.children.length > 0 || node.category.products.some(productId => availableProducts!.value.some(product => product.id === productId));
    }

    function sortTree(nodes: CategoryNode[]) {
        nodes.sort((a, b) => a.category.name.localeCompare(b.category.name));
        nodes.forEach(node => sortTree(node.children));
    }
                
    const categoryTree = computed<CategoryNode[]>({
        get: () => {
            const tree: CategoryNode[] = [];
            const nodeMap = new Map<string, CategoryNode>();
    
            // Map categories to nodes
            categories.value.forEach(category => {
                nodeMap.set(category.id, { category, children: [], products: availableProducts?.value.filter(p => p.categories.includes(category.id)) ?? [] });
            });
    
            // Build the tree
            nodeMap.forEach((node, _) => {
                if (node.category.parent) {
                    const parentNode = nodeMap.get(node.category.parent);
                    if (parentNode) {
                        node.parent = parentNode;
                        parentNode.children.push(node);
                    }
                } else {
                    tree.push(node); // Root node
                }
            });

            const filteredTree = availableProducts?.value ? tree.filter(rootNode => pruneTree(rootNode)) : tree;

            if (sort) {
                sortTree(filteredTree);
            }
            
            return filteredTree;
        },
        set: (v) => {
            v.forEach(node => {
                if (node.category.parent) {
                    node.category.parent = null;
                    productsApiService.updateProductCategoryParent({ categoryId: node.category.id, parentId: node.category.parent });
                }
            });
        },
    });

    return {
        categoryTree,
    };
}

export function useCategorySubTree(categoryId: Ref<string>, products: Ref<ProductViewObject[]>, categories: Ref<ProductCategoryViewObject[]>, sort = false) {
    
    function buildTree(node: CategoryNode, nodeMap: Map<string, CategoryNode>): boolean {
        let hasProductsOrValidChildren = node.products.length > 0;

        const children = categories.value
            .filter(category => category.parent === node.category.id)
            .map(category => nodeMap.get(category.id)!)
            .filter(childNode => childNode && buildTree(childNode, nodeMap));

        if (children.length > 0) {
            hasProductsOrValidChildren = true;
            node.children = children;
        }

        return hasProductsOrValidChildren;
    }

    function sortTree(nodes: CategoryNode[]) {
        nodes.sort((a, b) => a.category.name.localeCompare(b.category.name));
        nodes.forEach(node => sortTree(node.children));
    }

    const categorySubTree = computed<CategoryNode | undefined>(() => {
        const nodeMap = new Map<string, CategoryNode>();
        categories.value.forEach(category => {
            nodeMap.set(category.id, { 
                category, children: [], 
                products: products.value
                    .filter(p => p.categories.includes(category.id))
                    .sort((a, b) => a.name.localeCompare(b.name)), 
            });
        });

        const rootNode = nodeMap.get(categoryId.value);
        if (!rootNode || !buildTree(rootNode, nodeMap)) {
            return undefined;
        }

        if (sort) {
            sortTree(rootNode.children);
        }

        return rootNode;
    });

    return {
        categorySubTree,
    };
}

export function useProductsForCategory(categoryId: Ref<string>, products: Ref<ProductViewObject[]>, categories: Ref<ProductCategoryViewObject[]>, includeChildren = true, sort = false) {
    
    function collectCategoryIds(categoryId: string, categoryMap: Map<string, ProductCategoryViewObject>): string[] {
        const ids: string[] = [categoryId];
        const category = categoryMap.get(categoryId);
        if (category && category.children) {
            category.children.forEach(childId => {
                ids.push(...collectCategoryIds(childId, categoryMap));
            });
        }
        return ids;
    }

    function sortProducts(products: ProductViewObject[]) {
        return products.sort((a, b) => a.name.localeCompare(b.name));
    }

    const productsForCategory = computed(() => {
        if (includeChildren) {
            const categoryMap = new Map<string, ProductCategoryViewObject>();
            categories.value.forEach(category => categoryMap.set(category.id, category));
            const relevantCategoryIds = collectCategoryIds(categoryId.value, categoryMap);
            const relevantProducts = products.value.filter(product => product.categories.some(categoryId => relevantCategoryIds.includes(categoryId)));
            return sort ? sortProducts(relevantProducts) : relevantProducts;
        }

        const relevantProducts = products.value.filter(product => product.categories.includes(categoryId.value));
        return sort ? sortProducts(relevantProducts) : relevantProducts;
    });

    return {
        productsForCategory,
    };
}

export function useProductUrl(product: MaybeRef<ProductViewObject | undefined>) {
    const productUrl = computed(() => {
        const value = unref(product);
        return value ? `/produkter/${encodeURIComponent(value.name.split(' ').join('-').toLowerCase())}/${value.id}` : '';
    });

    return {
        productUrl,
    };
}

export function useCategoryUrl(node: Ref<CategoryNode>) {
    const categoryToUrl = (category: ProductCategoryViewObject) => encodeURIComponent(category.name.split(' ').join('-').toLowerCase());

    const url = computed(() => {
        const namePath = categoryToUrl(node.value.category);

        const parentPaths: string[] = [];

        let parent = node.value.parent;
        
        while (parent) {
            const parentNamePath = categoryToUrl(parent.category);
            parentPaths.push(parentNamePath);
            parent = parent.parent;
        }

        const combinedPaths = parentPaths.join('/');
        
        return parentPaths.length > 0 
            ? `/kategorier/${combinedPaths}/${namePath}/${node.value.category.id}` 
            : `/kategorier/${namePath}/${node.value.category.id}`;
    });

    return {
        url,
    };
}

export function useAdminProductUrl(product: MaybeRef<ProductViewObject | undefined>) {
    const productUrl = computed(() => {
        const value = unref(product);
        return value ? `/products/${value.id}` : '';
    });

    return {
        productUrl,
    };
}

export function getProductUrl(product: { id: string, name: string,  }) {
    return `/produkter/${encodeURIComponent(product.name.split(' ').join('-').toLowerCase())}/${product.id}`;
}

export function useProductCategoryPaths(product: Ref<ProductViewObject>, categories: Ref<ProductCategoryViewObject[]>) {
    const categoryPaths = computed<ProductCategoryViewObject[][]>(() => {
        const result: ProductCategoryViewObject[][] = [];

        for (let categoryId of product.value.categories) {
            const path: ProductCategoryViewObject[] = [];

            do 
            {
                const category = categories.value.find(x => x.id === categoryId);
                if (!category)
                    break;
            
                path.unshift(category);

                categoryId = category.parent as any;

            } while(categoryId);

            result.push(path);
        }

        return result;
    });

    return {
        categoryPaths,
    };
}

export function useProductCustomerAssets(productId: Ref<string>, customerId: Ref<string | null | undefined>, onUpdate?: () => void) {
    const assets = ref<ProductCustomerAssetViewObject[]>([]);

    watchDebounced([productId, customerId], async() => {
        if (!productId.value || !customerId.value) {
            assets.value = [];
            return;
        }

        const result = await productsApiService.getProductCustomerAssets(productId.value, customerId.value);
        assets.value = result?.assets ?? [];
        onUpdate?.();
    }, { immediate: true, debounce: 200 });

    const addOrUpdate = (_productId: string, _assets: ProductCustomerAssetViewObject[]) => {
        if (_productId === productId.value) {
            assets.value = _assets;
            onUpdate?.();
        }
    };

    useBusEvent('ProductCustomerAssetsUpdated', addOrUpdate);

    return {
        assets,
    };
}