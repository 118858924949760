<template>
    <footer>
        <div class="container">
            <div class="grid grid-cols-12 gap-5 text-14">
                <div class="col-span-12 md:col-span-4 flex flex-col gap-2">
                    <p class="text-16 font-bold">
                        SOCCO A/S
                    </p>

                    <div class="mb-5">
                        <p class="text-14">
                            Egholmvej 8
                        </p>
                        <p class="text-14">
                            7160 Tørring
                        </p>
                        <p class="text-14">
                            CVR: 33054602
                        </p>
                    </div>

                    <div>
                        <p class="text-14">
                            Tlf: <a :href="'tel:+4575852323'">+45 7585 2323</a>
                        </p>
                        <p class="text-14">
                            E-mail: <a :href="'mailto:socco@socco.dk'">socco@socco.dk</a>
                        </p>
                    </div>
                </div>

                <div class="col-span-12 md:col-span-4 flex flex-col gap-2">
                    <p class="text-16 font-bold">
                        INDHOLD
                    </p>

                    <router-link :to="{ path: '/page/about' }">
                        Om Socco A/S
                    </router-link>
                    <a href="https://socco.dk/private-label/">
                        Private label
                    </a>
                    <a href="https://socco.dk/privatlivspolitik/">
                        Privatlivspolitik
                    </a>
                    <router-link :to="{ name: $routes.PRODUCTS }">
                        Produkter
                    </router-link>
                    <a href="https://socco.dk/kontakt/">
                        Kontakt os
                    </a>
                </div>

                <div class="col-span-12 md:col-span-4 flex flex-col gap-2">
                </div>
            </div>

            <div class="footer-divider">
            </div>

            <div class="flex items-center justify-between gap-5">
                <div>
                    <router-link :to="{ name: $routes.HOME }" class="flex items-center">
                        <img src="/assets/images/logo-white.png" alt="bolig-swipe-logo" class="logo-image">
                    </router-link>
                </div>
                <div>
                    <button v-if="selectedLanguage" type="button" class="flex items-center" @click="changeLanguage()">
                        <FlagIcon class="flag-image" :country-code="selectedLanguage.countryCode"/>
                        <p class="font-bold text-14 ml-3">
                            {{ $translate(selectedLanguage.dictionaryKey) }}
                        </p>
                    </button>
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent } from 'vue';
import { DictionaryLanguageViewObject } from '@/api';
import { useShopDictionaryLanguages } from '@/core/compositions/dictionaryLanguages';
import dialogService from '@/core/dialog/dialog.service';

export default defineComponent({
    name: 'FooterComponent',
    setup(props) {
        const { languages } = useShopDictionaryLanguages();
        const selectedLanguage = computed<DictionaryLanguageViewObject>(() => {
            return languages.value.find(x => x.languageCode === window.languageCode)!;
        });
        
        return {
            selectedLanguage,
            changeLanguage: () => {
                dialogService.showModal({
                    component: defineAsyncComponent({ loader: () => import('@/components/language/ChangeLanguage.vue') }),
                }, {
                    size: 'xs',
                });
            },
        };
    },
});
</script>

<style lang="scss" scoped>
footer {
    flex-shrink: 0;
    padding: 25px;
    background-color: #202020;
    border-top: 3px solid theme('colors.green.primary');
    color: white;
}

.footer-divider {
    background-color: rgb(85, 79, 79);
    width: 100%;
    height: 2px;
    margin: 15px 0;
}

.logo-image {
    height: 30px;
    flex-shrink: 0;
    user-select: none;

    @screen md {
        height: 40px;
    }
}

.flag-image {
    width: 25px;
}

.available-payment-providers {
    margin: auto auto -25px auto;
    cursor: pointer;

    filter: brightness(0) invert(1);

    &:hover {
        filter: unset;
    }

    transition: filter 200ms ease-in-out;

    .payment-providers-image {
        height: 70px;
    }

    @screen sm {
        margin: auto -20px -25px auto;
    }
}

</style>
