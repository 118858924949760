<template>
    <router-link v-if="user" :to="{ name: $routes.HOME }">
        <p class="text-16 text-center font-bold">
            {{ user.fullName }}
        </p>
        <p class="text-13 text-center -mt-2">
            {{ user.email }}
        </p>
    </router-link>
    <div class="profile-menu">
        <router-link :to="{ name: $routes.HOME }" class="mb-2">
            <CIcon name="account"/>
            <span>Profil</span>
        </router-link>
        <div class="horizontal-divider"/>
        <!-- <router-link :to="{ name: $routes.HOME }" class="mb-2">
                        <CIcon name="store-plus"/>
                        <span>Opret bolig</span>
                    </router-link>
                    <router-link :to="{ name: $routes.HOME }" class="mb-2">
                        <CIcon name="home-group"/>
                        <span>Mine boliger</span>
                    </router-link>
                    <router-link :to="{ name: $routes.HOME }">
                        <CIcon name="heart"/>
                        <span>Vis favoritter</span>
                    </router-link> -->
        <div class="horizontal-divider"/>
        <button @click="logout()">
            <CIcon name="logout"/>
            <span>Log ud</span>
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import authenticationService from '@/core/authentication/authentication.service';
import dialogService from '@/core/dialog/dialog.service';
import { userStore } from '@/core/store/user/user.store';
import router from '@/router/router.index';
import { RouteNames } from '@/router/router.routes';

export default defineComponent({
    name: 'HeaderDropdownProfile',
    setup() {
        const { user } = userStore;
        const logout = async() => {
            const accepted = await dialogService.dialogConfirmation({
                title: 'Er du sikker på du vil logge ud?',
            });

            if (!accepted) return;

            await authenticationService.logout();
            await router.push({ name: RouteNames.HOME });
        };

        return {
            user,
            logout,
        };
    },
});
</script>

<style lang="scss" scoped>
.profile-menu {
    margin-top: 10px;
    
    button, a {
        width: 100%;
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }
}
</style>
