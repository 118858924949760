import { UserRole, ShopRouteAreaAccess } from '@/api';
import { RouteLocationNormalized, RouteRecordRaw, RouteMeta } from 'vue-router';

export interface RouteMetaExtended extends RouteMeta {
    allowAnonymous?: boolean;
    role?: UserRole;
    area: ShopRouteAreaAccess;
    breadcrumb: { text: string, pathName?: RouteNames }[]
}

export enum RouteNames {
    AUTH = 'AUTH',
    AUTH_LOGIN = 'AUTH_LOGIN',
    AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD',
    AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD',

    HOME = 'HOME',
    PRODUCTS = 'PRODUCTS',
    PRODUCT_DETAILS_PAGE = 'PRODUCT_DETAILS_PAGE',
    PRODUCT_CATEGORIES_PAGE = 'PRODUCT_CATEGORIES_PAGE',
    PRODUCT_CATEGORY_PAGE = 'PRODUCT_CATEGORY_PAGE',

    ORDER_HISTORY_PAGE = 'ORDER_HISTORY_PAGE',

    CONTENT_PAGE = 'CONTENT_PAGE',

    CHECKOUT_PAGE = 'CHECKOUT_PAGE',
}

const createPath = (...paths: string[]) => {
    return `/${paths.join('/')}`;
};

export const routes: Array<RouteRecordRaw> = [
    {
        path: createPath(''),
        name: RouteNames.HOME,
        component: () => import('@/pages/Home.vue'),
        meta: {
            allowAnonymous: true,
            area: ShopRouteAreaAccess.Home,
        } as RouteMetaExtended,
    },
    {
        path: createPath('produkter'),
        name: RouteNames.PRODUCTS,
        component: () => import('@/pages/ProductsPage.vue'),
        meta: {
            allowAnonymous: true,
            area: ShopRouteAreaAccess.Products,
        } as RouteMetaExtended,
    },
    {
        path: createPath('produkter', ':pathMatch(.*)*'),
        name: RouteNames.PRODUCT_DETAILS_PAGE,
        component: () => import('@/pages/ProductDetailsPage.vue'),
        meta: {
            allowAnonymous: true,
            area: ShopRouteAreaAccess.Products,
        } as RouteMetaExtended,
        props: (route: RouteLocationNormalized) => {
            const pathSegments = route.params.pathMatch as string[];
            const productId = pathSegments[pathSegments.length - 1];

            return {
                productId: productId,
            };
        },
    },
    {
        path: createPath('kategorier'),
        name: RouteNames.PRODUCT_CATEGORIES_PAGE,
        component: () => import('@/pages/ProductCategoriesPage.vue'),
        meta: {
            allowAnonymous: true,
            area: ShopRouteAreaAccess.Categories,
        } as RouteMetaExtended,
    },
    {
        path: createPath('kategorier', ':pathMatch(.*)*'),
        name: RouteNames.PRODUCT_CATEGORY_PAGE,
        component: () => import('@/pages/ProductCategoryPage.vue'),
        meta: {
            allowAnonymous: true,
            area: ShopRouteAreaAccess.Categories,
        } as RouteMetaExtended,
        props: (route: RouteLocationNormalized) => {
            const pathSegments = route.params.pathMatch as string[];
            const categoryId = pathSegments[pathSegments.length - 1];

            return {
                categoryId: categoryId,
            };
        },
    },
    {
        path: createPath('checkout'),
        name: RouteNames.CHECKOUT_PAGE,
        component: () => import('@/pages/CheckoutPage.vue'),
        meta: {
            allowAnonymous: false,
            area: ShopRouteAreaAccess.Checkout,
        } as RouteMetaExtended,
    },
    {
        path: createPath('ordre-historik'),
        name: RouteNames.ORDER_HISTORY_PAGE,
        component: () => import('@/pages/OrderHistory.vue'),
        meta: {
            allowAnonymous: false,
            area: ShopRouteAreaAccess.OrderHistory,
        } as RouteMetaExtended,
    },
    {
        path: '/page/:pathMatch(.*)*',
        name: RouteNames.CONTENT_PAGE,
        component: () => import('@/pages/ContentPage.vue'),
        meta: {
            allowAnonymous: true,
            area: ShopRouteAreaAccess.Home,
        } as RouteMetaExtended,
    },
    {
        path: createPath('auth'),
        name: RouteNames.AUTH,
        component: () => import('@/pages/login/LoginPage.vue'),
        meta: {
            allowAnonymous: true,
            area: ShopRouteAreaAccess.Auth,
        },
        children: [
            {
                path: createPath('auth', 'login'),
                name: RouteNames.AUTH_LOGIN,
                component: () => import('@/pages/login/LoginForm.vue'),
                meta: {
                    allowAnonymous: true,
                    area: ShopRouteAreaAccess.Auth,
                },
            },
            {
                path: createPath('auth', 'forgot-password'),
                name: RouteNames.AUTH_FORGOT_PASSWORD,
                component: () => import('@/pages/login/ForgotPasswordForm.vue'),
                meta: {
                    allowAnonymous: true,
                    area: ShopRouteAreaAccess.Auth,
                },
            },
            {
                path: createPath('auth', 'reset-password'),
                name: RouteNames.AUTH_RESET_PASSWORD,
                component: () => import('@/pages/login/ResetPasswordForm.vue'),
                props: (route: RouteLocationNormalized) => ({
                    token: route.query.token,
                }),
                meta: {
                    allowAnonymous: true,
                    area: ShopRouteAreaAccess.Auth,
                },
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/pages/404.vue'),
        meta: {
            allowAnonymous: true,
        },
    },
];
