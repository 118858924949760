<template>
    <div class="web__app__layout">
        <TopHeader/>

        <main>
            <RouterView v-slot="{ Component }">
                <Transition
                    mode="out-in"
                    appear
                    :enter-active-class="enterActiveClass"
                    :leave-active-class="leaveActiveClass">
                    <component :is="Component" class="page"/>
                </Transition>
            </RouterView>

            <BreakPoints>
                <template #sm>
                    <BottomFooter class="mt-15"/>
                </template>
            </BreakPoints>
        </main>

        <BreakPoints>
            <template #mobile-sm>
                <BottomMenu/>
            </template>
        </BreakPoints>
    </div>
        
    <ModalHost/>
    <ProgressBar/>
    <ContextMenuHost/>
    <NotificationsHost/>
    <ServerConnectionStatus/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAnimation } from '@/core/animation/animateComposable';
import ModalHost from '@/core/dialog/ModalHost.vue';
import ContextMenuHost from '@/core/context-menus/ContextMenuHost.vue';
import NotificationsHost from '@/core/notifications/NotificationsHost.vue';
import ProgressBar from '@/core/loading/ProgressBar.vue';
import ServerConnectionStatus from '@/core/signalr/ServerConnectionStatus.vue';

import TopHeader from '@/components/header/Header.vue';
import BottomFooter from '@/components/footer/Footer.vue';
import Breadcrumb from './components/breadcrumb/Breadcrumb.vue';
import BottomMenu from './components/menus/BottomMenu.vue';

export default defineComponent({
    name: 'App',
    components: {
        ModalHost,
        ContextMenuHost,
        NotificationsHost,
        ProgressBar,
        ServerConnectionStatus,
        TopHeader, BottomFooter,
        Breadcrumb,
        BottomMenu,
    },
    setup() {
        const { enterActiveClass, leaveActiveClass } = useAnimation({ type: 'fade' });

        return {
            enterActiveClass,
            leaveActiveClass,
        };
    },
});
</script>

<style>
#app {
    position: relative;

    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    height: 100%;
    overflow: hidden;
}
</style>

<style lang="scss" scoped>
.web__app__layout {
    position: relative;

    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    height: 100%;
    overflow: hidden;
}

main {
    position: relative;

    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    overflow-y: auto;
    overflow-x: hidden;

    .page {
        @apply flex flex-col flex-1;
    }
}
</style>
