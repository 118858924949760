import { debounce, DebouncedFunc } from 'lodash-es';
import Deferred from '@/core/async/Deferred';

export abstract class BulkFetch<TResult> {
    protected activeListeners: Deferred<TResult>[] = [];
    protected debouncedFetch: DebouncedFunc<() => Promise<void>>;

    constructor(protected name: string, protected debounceTime: number = 100) {
        this.debouncedFetch = debounce(this.fetch, debounceTime);
    }

    public async requestData(): Promise<TResult | undefined> {
        const deferred = new Deferred<TResult>();
        this.activeListeners.push(deferred);
        this.debouncedFetch();
        return deferred.promise;
    }

    private async fetch() {
        const listeners = this.activeListeners;
        this.activeListeners = [];

        try {
            const result = await this.apiCall();
            this.handleResultFromServer(listeners, result);
        } catch (e) {
            this.handleResultFromServer(listeners);
            throw e;
        }
    }

    private handleResultFromServer(listeners: Deferred<TResult>[], result?: TResult) {
        if (result) {
            listeners.forEach(listener=> {
                listener.resolve(result);
            });
        } else {
            listeners.forEach(listener => {
                listener.reject(`[${this.name}]: Unable to find a result` as any);
            });
        }
    }

    protected abstract apiCall(): Promise<TResult>;
}
