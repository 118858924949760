import type { Config } from 'tailwindcss';

export default {
    content: [
        './index.html',
        './src/**/*.{vue,js,ts,jsx,tsx}',
    ],
    safelist: [
        {
            pattern: /col-span-(1|2|3|4|5|6|7|8|9|10|11|12)/,
        },
    ],
    mode: 'jit',
    theme: {
        fontSize: {
            9: '0.9rem',
            10: '1rem',
            11: '1.1rem',
            12: '1.2rem',
            13: '1.3rem',
            14: '1.4rem',
            15: '1.5rem',
            16: '1.6rem',
            17: '1.7rem',
            18: '1.8rem',
            20: '2rem',
            22: '2.2rem',
            24: '2.4rem',
            26: '2.6rem',
            28: '2.8rem',
            32: '3.2rem',
            38: '3.8rem',
            42: '4.2rem',
            56: '5.6rem',
            80: '8rem',
            96: '9.6rem',
        },
        screens: {
            mobile: '320px',
            xs: '576px',
            sm: '767px',
            md: '960px',
            lg: '1280px',
            xl: '1440px',
        },
        container: {
            center: true,
            screens: {
                xs: '576px',
                sm: '767px',
                md: '960px',
                lg: '1280px',
            },
        },
        extend: {
            zIndex: {
                '1': '1',
                'topMenu': '100',
                'spinner': '10000',
            },
            maxWidth: {
                sm: '32rem',
                md: '48rem',
                lg: '60rem',
            },
            margin: {
                15: '3.75rem',
                50: '5rem',
                80: '8rem',
                90: '9rem',
                95: '9.5rem',
                100: '10rem',
            },
            padding: {
                15: '1.5rem',
                50: '5rem',
                80: '8rem',
            },
            colors: {
                text: '#000',
                background: '#f3f4f6',
                elements: '#fff',
                border: '#d1d5db',
                shadow: '#1a1f33',
                backdrop: '#1a1f33',
                blue: {
                    dark: '#118ab2',
                    primary: '#118ab2',
                },
                green: {
                    light: '#249269',
                    dark: '#166534',
                    primary: '#079e69',
                },
                red: {
                    light: '#ED217C',
                    dark: '#DC2626',
                },
                yellow: {
                    dark: 'rgb(234 179 8)',
                },
                orange: {
                    primary: '#FFAA00',
                },
                tabs: {
                    item: '#079e69',
                    itemActive: '#166534',
                    color: '#fff',
                    activeColor: '#fff',
                },
                cta: {
                    default: {
                        text: '#fff',
                        background: '#079e69',
                    },
                },
            },
        },
    },
    plugins: [],
} satisfies Config;

