<template>
    <div class="bottom-menu">
        <router-link :to="{ name: $routes.HOME }">
            <CIcon name="view-dashboard" height="30" width="30"/>
        </router-link>
        <router-link :to="{ name: $routes.PRODUCTS }">
            <CIcon name="barrel" height="30" width="30"/>
        </router-link>
        <button v-if="!activeCustomer" @click="login('ORDER_HISTORY_PAGE')">
            <CIcon name="clipboard-check-outline" height="30" width="30"/>
        </button>
        <router-link v-else :to="{ name: $routes.ORDER_HISTORY_PAGE }">
            <CIcon name="clipboard-check-outline" height="30" width="30"/>
        </router-link>
        <button v-if="!activeCustomer" @click="selectCustomer('CHECKOUT_PAGE')">
            <CIcon name="basket" height="30" width="30"/>
        </button>
        <router-link v-else :to="{ name: $routes.CHECKOUT_PAGE }">
            <div class="relative">
                <CIcon name="basket" height="30" width="30"/>
                <span v-if="cart?.lineItems?.length"
                      class="basket-size-indicator"
                      :class="{ 'animate__animated animate__pulse animate__duration_300 animate__infinite': applyCartAnim }">
                    {{ cart.lineItems.length }}
                </span>
            </div>
        </router-link>
        <router-link :to="{ name: $routes.HOME }">
            <CIcon name="account-circle" height="30" width="30"/>
        </router-link>
    </div>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, ref, watch } from 'vue';
import { userStore } from '@/core/store/user/user.store';
import { RouteNames } from '@/router/router.routes';
import authenticationService from '@/core/authentication/authentication.service';
import dialogService from '@/core/dialog/dialog.service';

export default defineComponent({
    name: 'BottomMenu',
    setup() {
        const { isLoggedIn, user, activeCustomer, cart } = userStore;

        const login = async(to?: keyof typeof RouteNames) => {
            await authenticationService.loginPrompt(to);
        };

        const selectCustomer = async(to?: keyof typeof RouteNames) => {
            if (!isLoggedIn.value) {
                await login(to);

                if (!isLoggedIn.value) 
                    return;
            }

            dialogService.showModal({
                component: defineAsyncComponent({ loader: () => import('@/components/authentication/SelectCustomerForm.vue') }),
            }, {
                size: 'xs',
            });
        };

        const applyCartAnim = ref(false);
        let clearAnimTimeout: number | undefined;

        watch(() => cart.value?.lineItems?.length, () => {
            applyCartAnim.value = true;

            clearInterval(clearAnimTimeout);
            clearAnimTimeout = setInterval(() => applyCartAnim.value = false, 600);
        });

        return {
            isLoggedIn, user, activeCustomer, cart,
            applyCartAnim,
            login,
            selectCustomer,
        };
    },
});
</script>

<style lang="scss" scoped>
.bottom-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    height: 45px;
    background-color: white;

    border-top: 3px solid theme('colors.green.primary');

    a, button {
        flex: 1 0 auto;
        padding: 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.basket-size-indicator {
    position: absolute;
    top: -3px;
    right: -8px;
    font-weight: medium;
    font-size: 13px;
    background-color: theme('colors.yellow.300');
    border-radius: 9999px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
}

.router-link-exact-active {
    color: theme('colors.green.primary');
}
</style>
