<template>
    <div class="header-dropdown">
        <div v-if="!loaded" class="flex justify-center items-center p-5">
            <Spinner :height="40" :width="40"/>
        </div>
        <div v-else-if="categoryTree.length > 0" class="category-list">
            <HeaderDropdownCategoryNode v-for="node in categoryTree" :key="node.category.id" :node="node" :index="0"/>
        </div>
        <div v-else-if="activeCustomer">
            <p class="mb-5 text-center text-14">
                <span class="font-bold">{{ activeCustomer.name }}</span> er ikke tilknyttet nogen produkter som du kan handle.
            </p>
            <p class="mb-5 text-center text-14">
                Kontakt venligst <span class="font-bold">Socco A/S</span>.
            </p>
        </div>
        <div v-else>
            <p class="mb-5 text-center text-14">
                Der er ikke nogen produkter tilgængeligt som du kan handle.
            </p>
            <p class="mb-5 text-center text-14">
                Kontakt venligst <span class="font-bold">Socco A/S</span>.
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { userStore } from '@/core/store/user/user.store';
import { CategoryTreeNode } from '@/api';
import productsApiService from '@/core/api/controllers/productsApi.service';

import HeaderDropdownCategoryNode from './HeaderDropdown_CategoryNode.vue';

export default defineComponent({
    name: 'HeaderDropdownProducts',
    components: { HeaderDropdownCategoryNode },
    setup() {
        const loaded = ref(false);
        const { activeCustomer} = userStore;
        
        const categoryTree = ref<CategoryTreeNode[]>([]);

        (async function() {
            const result = await productsApiService.getCategoryTree({ includeProducts: true, sort: true });
            if (result) {
                categoryTree.value = result.nodes;
            }

            loaded.value = true;
        })();

        return {
            loaded,
            activeCustomer,
            categoryTree,
        };
    },
});
</script>

<style lang="scss" scoped>
.header-dropdown {
    margin: -8px;
    padding-bottom: 8px;
    @apply flex flex-col overflow-hidden;
}

.category-list {
    max-height: 75vh;
    overflow: auto;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
        display: none;
    }
}
</style>