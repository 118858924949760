<template>
    <div>
        <div v-if="!cart?.lineItems?.length">
            <p class="text-14 text-center">
                Du har ingen produkter i din kurv
            </p>
        </div>
        <div v-else-if="!loaded" class="flex justify-center items-center p-5">
            <Spinner :height="40" :width="40"/>
        </div>
        <div v-else>
            <div class="lineitems">
                <LineItem v-for="product in products" :key="product.id" :product="product"/>
            </div>

            <div class="mt-3 flex justify-between gap-3">
                <CallToAction class="text-12" @click="clearBasket()">
                    Tøm kurv
                </CallToAction>
                <CallToAction class="text-12" :to="{ name: $routes.CHECKOUT_PAGE }" @click="close()">
                    Gå til Kurv
                </CallToAction>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { userStore } from '@/core/store/user/user.store';
import { useProducts } from '@/core/compositions/products.compositions';

import LineItem from './HeaderDropdown_MiniBasket_LineItem.vue';

export default defineComponent({
    name: 'MiniBasket',
    props: {
        close: {
            type: Function,
            required: true,
        },
    },
    components: { LineItem },
    setup() {
        const { cart, cartUpdating, activeCustomer } = userStore;

        const productIds = computed(() => cart.value?.lineItems?.map(l => l.productId) ?? []);

        const loaded = ref(false);
        const { products } = useProducts(productIds, () => loaded.value = true);

        return {
            cart,
            loaded,
            clearBasket: async() => await userStore.clearBasket(),
            products,
            activeCustomer,
            cartUpdating,
        };
    },
});
</script>

<style lang="scss" scoped>
.lineitems {
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;

    margin-right: -8px;
    margin-left: -8px;
}
</style>
