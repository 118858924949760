<template>
    <div ref="picker"
         class="relative h-full"
         @mouseenter="isOutside = false"
         @mouseleave="isOutside = true">
        <slot :active="!isOutside"/>

        <Transition
            mode="out-in"
            appear
            :enter-active-class="enterActiveClass"
            :leave-active-class="leaveActiveClass">
            <div v-if="!isOutside" class="header-dropdown" :class="anchor">
                <slot name="menu" :close="() => isOutside = true"/>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent, ref } from 'vue';
import { useAnimation } from '@/core/animation/animateComposable';
import dialogService from '@/core/dialog/dialog.service';
import authenticationService from '@/core/authentication/authentication.service';

export default defineComponent({
    name: 'HeaderDropdown',
    props: {
        anchor: {
            type: String as PropType<'center' | 'right' | 'left'>,
            default: 'center',
        },
    },
    setup() {
        const { enterActiveClass, leaveActiveClass } = useAnimation({ type: 'fade' });

        const isOutside = ref(true);
        const picker = ref<HTMLElement | null>(null);

        const logout = async() => {
            const accepted = await dialogService.dialogConfirmation({
                title: 'Er du sikker på du vil logge ud?',
            });

            if (!accepted) return;

            await authenticationService.logout();
        };

        return {
            picker,
            isOutside,
            logout,

            enterActiveClass, leaveActiveClass,
        };
    },
});
</script>

<style lang="scss" scoped>
.header-dropdown {
    position: absolute;
    top: calc(100%);

    &.center {
        left: 50%;
        transform: translateX(-50%);
    }

    &.right {
        right: 0;
    }

    &.left {
        left: 0;
    }

    background-color: theme('colors.background');
    font-size: 14px;

    z-index: 101;

    padding: 8px;
    width: 285px;
    max-width: 90vw;

    border-left: 3px solid theme('colors.green.primary');
    border-right: 3px solid theme('colors.green.primary');
    border-bottom: 3px solid theme('colors.green.primary');

    @apply shadow-lg;
}


.horizontal-divider {
    height: 2px;
    width: 100%;
    margin: 10px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
}
</style>
