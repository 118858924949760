import { ref } from 'vue';
import { DictionaryLanguageViewObject } from '@/api';
import { adminDictionaryLanguageBulkFetch, shopDictionaryLanguageBulkFetch } from '@/core/bulk/services/dictionaryLanguages.bulk.service';

export function useAdminDictionaryLanguages(callback?: (languages: DictionaryLanguageViewObject[]) => void) {
    const languages = ref<DictionaryLanguageViewObject[]>([]);
        
    const getLanguages = async() => {
        const result = await adminDictionaryLanguageBulkFetch.requestData();
        if (result) {
            callback?.(result);
            languages.value = result;
        }
    };

    getLanguages();

    return {
        languages,
        getLanguages,
    };
}

export function useShopDictionaryLanguages(callback?: (languages: DictionaryLanguageViewObject[]) => void) {
    const languages = ref<DictionaryLanguageViewObject[]>([]);
        
    const getLanguages = async() => {
        const result = await shopDictionaryLanguageBulkFetch.requestData();
        if (result) {
            callback?.(result);
            languages.value = result;
        }
    };

    getLanguages();

    return {
        languages,
        getLanguages,
    };
}
