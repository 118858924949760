<template>
    <div v-if="lineItem" class="lineitem">
        <div class="line-item-image mr-3">
            <img v-on-error :src="product.images[0]" alt="product-image">
        </div>
        <div class="w-full">
            <router-link :to="{ path: productUrl }" class="font-medium hover:underline text-12">
                <span>{{ product.name }}</span>
            </router-link>
            <p class="text-12">
                Varenr.: {{ product.itemId }}
            </p>
            <CartProductControl :product="product" mini class="mt-1 ml-auto"/>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent, computed } from 'vue';
import { userStore } from '@/core/store/user/user.store';
import { ProductViewObject } from '@/api';
import { useProductUrl } from '@/core/compositions/products.compositions';

import CartProductControl from '@/components/shop/CartProductControl.vue';

export default defineComponent({
    name: 'LineItem',
    props: {
        product: {
            type: Object as PropType<ProductViewObject>,
            required: true,
        },
    },
    components: { CartProductControl },
    setup(props) {
        const { cart } = userStore;

        const lineItem = computed(() => cart.value?.lineItems?.find(x => x.productId === props.product.id));

        const { productUrl } = useProductUrl(props.product);

        return {
            lineItem,
            productUrl,
        };
    },
});
</script>

<style lang="scss" scoped>
.lineitem {
    display: flex;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 5px;
    padding-right: 8px;
    overflow: hidden;
    margin: 2px 0;
    flex-shrink: 0;

    &:hover {
        background-color: theme('colors.gray.200');
    }

    .line-item-image {
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }
}
</style>
