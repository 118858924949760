<template>
    <div v-if="canPurchaseProduct" class="cart-product-control" :class="{ mini }">
        <button name="count" :disabled="disabled || count <= 0 || cartUpdating" class="cart-btn" @click="count--">
            <CIcon name="minus"
                   :height="mini ? '18' : '22'"
                   :width="mini ? '18' : '22'"/>
        </button>
        <input ref="inputField"
               v-model="count"
               v-prohibit-zoom
               autocorrect="off"
               autocapitalize="off"
               spellcheck="false"
               name="count"
               min="0"
               max="9999"
               :disabled="disabled || cartUpdating"
               class="cart-input"
               type="number">
        <button name="count" :disabled="disabled || cartUpdating" class="cart-btn" @click="count++">
            <CIcon name="plus" 
                   :height="mini ? '18' : '22'"
                   :width="mini ? '18' : '22'"/>
        </button>
    </div>
    <div v-else>
        <div v-if="isLoggedIn" class="flex gap-3 items-center">
            <p class="text-13 font-bold">
                Ikke tilgængelig
            </p>
            <button v-if="count > 1"
                    name="count"
                    :disabled="count <= 0 || cartUpdating"
                    class="cart-btn"
                    title="Fjern"
                    @click="count = 0">
                <CIcon name="minus"
                       :height="mini ? '18' : '22'"
                       :width="mini ? '18' : '22'"/>
            </button>
        </div>
        <CallToAction v-else @click="login()">
            <CIcon name="login" height="20" width="20" class="hidden md:inline-block"/>
            <CIcon name="login" height="16" width="16" class="md:hidden"/>
            <p class="text-11 md:text-13 truncate ml-3">
                Log ind for at handle
            </p>
        </CallToAction>
    </div>
</template>

<script lang="ts">
import { PropType, computed, defineAsyncComponent, defineComponent, ref } from 'vue';
import { userStore } from '@/core/store/user/user.store';
import { ProductViewObject } from '@/api';
import { canPurchaseProduct as productCanBePurchased } from '@/core/compositions/products.compositions';
import dialogService from '@/core/dialog/dialog.service';
import authenticationService from '@/core/authentication/authentication.service';

export default defineComponent({
    name: 'CartProductControl',
    props: {
        product: {
            type: Object as PropType<ProductViewObject>,
            required: true,
        },
        isVariant: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        mini: {
            type: Boolean,
            default: false,
        },
        suggestVariants: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const { isLoggedIn, cart, cartUpdating, activeCustomer } = userStore;

        const inputField = ref<HTMLInputElement | null>(null);

        const lineItem = computed(() => cart.value?.lineItems?.find(x => x.productId === props.product.id));

        const count = computed<number>({
            get: () => lineItem.value?.amount ?? 0,
            set: async(v) => {
                if (typeof v === 'number') {
                    let productId = props.product.id;

                    if (props.suggestVariants && v > 0 && !lineItem.value && props.product.variants.length > 0) {
                        await dialogService.showModal<string>({
                            component: defineAsyncComponent({ loader: () => import('./ProductVariantSelector.vue') }),
                            componentAttrs: {
                                product: props.product,
                            },
                        }, {
                            size: 'sm',
                        });
                    } else {
                        const amount = userStore.addOrUpdateLineItem(productId, v, 'Replace');
                        if (v !== amount && inputField.value) {
                            inputField.value.value = amount as any;
                        }
                    }
                } else if (inputField.value) {
                    inputField.value.value = 0 as any;
                }
            },
        });

        const canPurchaseProduct = computed(() => productCanBePurchased(props.product));

        return {
            cart,
            count,
            inputField,
            activeCustomer,
            cartUpdating,
            isLoggedIn,
            canPurchaseProduct,

            login: async() => {
                await authenticationService.loginPrompt();
            },
        };
    },
});
</script>

<style lang="scss" scoped>
.cart-btn {
    border-radius: 0 !important;
    height: 100%;
    width: 40px;
    background-color: theme('colors.gray.300');
}

.cart-product-control {
    display: flex;
    align-items: center;
    height: 40px;
    flex-shrink: 0;
    border: 1px solid theme('colors.border');
    width: fit-content;

    .cart-input {
        width: 50px;
        height: 100%;
        border-right: 1px solid theme('colors.border');
        border-left: 1px solid theme('colors.border');
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border-radius: 0 !important;
        -webkit-border-radius: 0px !important;
        appearance: textfield;
        -moz-appearance: textfield;
        -webkit-appearance: none;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        &:focus {
            outline: none;
        }
    }

    &.mini {
        height: 25px;

        .cart-input {
            width: 40px;
            font-size: 12px;
        }

        .cart-btn {
            width: 25px;
        }
    }
}
</style>
