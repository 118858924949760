import { debounce, DebouncedFunc } from 'lodash-es';
import Deferred from '@/core/async/Deferred';

export abstract class IdBulkFetch<TResult, TKey extends string | number> {
    protected activeListenersById: Map<TKey, Deferred<TResult>[]> = new Map<TKey, Deferred<TResult>[]>();
    protected debouncedFetch: DebouncedFunc<() => Promise<void>>;

    constructor(protected name: string, protected debounceTime: number = 100) {
        this.debouncedFetch = debounce(this.fetch, debounceTime);
    }

    public async requestData(id: TKey): Promise<TResult | undefined> {
        const deferred = new Deferred<TResult>();

        const listeners = this.activeListenersById.get(id);
        if (listeners) {
            listeners.push(deferred);
        } else {
            this.activeListenersById.set(id, [deferred]);
        }

        this.debouncedFetch();
        return deferred.promise;
    }

    private async fetch() {
        const listeners = this.activeListenersById;
        this.activeListenersById = new Map<TKey, Deferred<TResult>[]>();

        try {
            const ids = Array.from(listeners.keys());
            const result = await this.apiCall(ids);
            this.handleResultFromServer(listeners, result);
        } catch (e) {
            this.handleResultFromServer(listeners, null);
            throw e;
        }
    }

    private handleResultFromServer(listeners: Map<TKey, Deferred<TResult>[]>, result: Record<TKey, TResult> | null) {
        listeners.forEach((listener: Deferred<TResult>[], id: TKey) => {
            const data = result && result[id];
            if (data != null) {
                listener.forEach(l => l.resolve(data));
            } else {
                listener.forEach(l => l.reject(`[${this.name}]: Unable to find a result for id: ${id}` as any));
            }
        });
    }

    protected abstract apiCall(ids: TKey[]): Promise<Record<TKey, TResult>>;
}
