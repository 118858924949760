import { ProductViewObject } from '@/api';
import { IdsBulkFetch } from '@/core/bulk/idsBulkFetch';
import productsApiService from '@/core/api/controllers/productsApi.service';

class ProductsService extends IdsBulkFetch<ProductViewObject, string> {
    protected async apiCall(productIds: string[]): Promise<ProductViewObject[] | undefined> {
        const result = await productsApiService.getProductsByIds({ productIds });
        return result?.products;
    }
}

export default new ProductsService('ProductsService');
