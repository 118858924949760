import { debounce, DebouncedFunc, uniq } from 'lodash-es';
import Deferred from '@/core/async/Deferred';

export abstract class IdsBulkFetch<TResult extends { id: TKey; }, TKey extends string | number> {
    protected activeListenersById: Map<TKey[], Deferred<TResult[]>[]> = new Map<TKey[], Deferred<TResult[]>[]>();
    protected debouncedFetch: DebouncedFunc<() => Promise<void>>;

    constructor(protected name: string, protected debounceTime: number = 100) {
        this.debouncedFetch = debounce(this.fetch, debounceTime);
    }

    public async requestData(ids: TKey[]): Promise<TResult[] | undefined> {
        const deferred = new Deferred<TResult[]>();

        const listeners = this.activeListenersById.get(ids);
        if (listeners) {
            listeners.push(deferred);
        } else {
            this.activeListenersById.set(ids, [deferred]);
        }

        this.debouncedFetch();
        return deferred.promise;
    }

    private async fetch() {
        const listeners = this.activeListenersById;
        this.activeListenersById = new Map<TKey[], Deferred<TResult[]>[]>();

        try {
            const ids = uniq(Array.from(listeners.keys()).flat());
            const result = await this.apiCall(ids);
            this.handleResultFromServer(listeners, result);
        } catch (e) {
            this.handleResultFromServer(listeners, null);
            throw e;
        }
    }

    private handleResultFromServer(listeners: Map<TKey[], Deferred<TResult[]>[]>, result: TResult[] | null | undefined) {
        listeners.forEach((listener: Deferred<TResult[]>[], ids: TKey[]) => {
            const data = result?.filter(x => ids.includes(x.id));
            if (data != null) {
                listener.forEach(l => l.resolve(data));
            } else {
                listener.forEach(l => l.reject(`[${this.name}]: Unable to find a result for ids: ${ids.join(', ')}` as any));
            }
        });
    }

    protected abstract apiCall(ids: TKey[]): Promise<TResult[] | null | undefined>;
}
