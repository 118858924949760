import { ProductViewObject } from '@/api';
import { IdBulkFetch } from '@/core/bulk/idBulkFetch';
import productsApiService from '@/core/api/controllers/productsApi.service';

class ProductsService extends IdBulkFetch<ProductViewObject, string> {
    protected async apiCall(productIds: string[]): Promise<{ [key: string]: ProductViewObject }> {
        const result = await productsApiService.getProductsByIds({ productIds });
        return result?.products.reduce((a, b) => {
            a[b.id] = b;
            return a;
        }, {}) ?? {};
    }
}

export default new ProductsService('ProductsService');
