import { App, defineAsyncComponent } from 'vue';

const Breadcrumb = defineAsyncComponent(() => import('./breadcrumb/Breadcrumb.vue'));
const FlagIcon = defineAsyncComponent(() => import('./language/FlagIcon.vue'));
const HelpMarker = defineAsyncComponent(() => import('./generic/HelpMarker.vue'));

export default async function config(app: App): Promise<void> {
    app.component('Breadcrumb', Breadcrumb);
    app.component('FlagIcon', FlagIcon);
    app.component('HelpMarker', HelpMarker);
}
