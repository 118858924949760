import { BulkFetch } from '@/core/bulk/bulkFetch';
import { DictionaryLanguageViewObject } from '@/api';
import dictionaryApiService from '@/core/api/controllers/dictionaryApi.service';

class AdminDictionaryLanguageBulkFetch extends BulkFetch<DictionaryLanguageViewObject[]> {
    protected async apiCall(): Promise<DictionaryLanguageViewObject[]> {
        const result = await dictionaryApiService.getSupportedAdminLanguages();
        return result?.languages ?? [];
    }
}

class ShopDictionaryLanguageBulkFetch extends BulkFetch<DictionaryLanguageViewObject[]> {
    protected async apiCall(): Promise<DictionaryLanguageViewObject[]> {
        const result = await dictionaryApiService.getSupportedShopLanguages();
        return result?.languages ?? [];
    }
}

const adminDictionaryLanguageBulkFetch = new AdminDictionaryLanguageBulkFetch('AdminDictionaryLanguageBulkFetch');
const shopDictionaryLanguageBulkFetch = new ShopDictionaryLanguageBulkFetch('ShopDictionaryLanguageBulkFetch');

export {
    adminDictionaryLanguageBulkFetch,
    shopDictionaryLanguageBulkFetch,
};
